<template>
  <div class="h-[calc(100vh-68px)] flex flex-col">
    <Toolbar class="w-full mx-auto px-8 py-3 flex justify-between h-12 z-10" />
    <div class="flex-grow flex overflow-hidden" >
      <SlidesThumbnailPanel
        v-model:slides="parsedSlides"
        :currentSlideIndex="currentSlideIndex"
        @select-slide="selectSlide"
      />
      <div class="flex-grow transition-all duration-300 ease-in-out">
        <div class="reveal h-full">
          <div class="slides">
            <section v-if="parsedSlides.length === 0">Loading...</section>
            <template v-else>
              <Slide
                v-for="(slide, index) in parsedSlides"
                :key="index"
                :slide="slide"
                :index="index"
                ref="editorContainer"
              />
            </template>
          </div>
        </div>
      </div>
      <SidePanel />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, provide, watch, nextTick, computed, inject, reactive } from 'vue'
import Reveal from 'reveal.js'
import Slide from '@/features/slides_editor/components/slides/Slide.vue'
import Toolbar from '@/features/slides_editor/components/editor/Toolbar.vue'
import SidePanel from '@/features/slides_editor/components/editor/SidePanel.vue'
import SlidesThumbnailPanel from '@/features/slides_editor/components/editor/SlidesThumbnailPanel.vue'
import { useSlideHelpers } from '@/features/slides_editor/composables'
import { useSidePanelHelpers } from '@/features/slides_editor/composables/useSidePanelHelpers'

export default {
  name: 'SlidesEditor',
  props: {
    slides: {
      type: String,
      required: true,
      validator(value) {
        try {
          JSON.parse(value)
          return true
        } catch (e) {
          console.error('Invalid JSON:', e)
          return false
        }
      }
    },
    prep_overview: {
      type: String,
      required: true
    },
    create_path: {
      type: String,
      required: true
    },
    prep_path: {
      type: String,
      required: true
    },
    layouts: {
      type: String,
      required: true,
      validator(value) {
        try {
          JSON.parse(value)
          return true
        } catch (e) {
          console.error('Invalid JSON:', e)
          return false
        }
      }
    }
  },
  components: {
    Slide,
    Toolbar,
    SidePanel,
    SlidesThumbnailPanel
  },
  setup(props) {
    const parsedSlides = ref([])
    const parsedLayouts = ref([])
    const currentEditor = ref(null)
    const saveStatusMessage = ref('')
    const isSaving = ref(false)
    const editorContainer = ref(null)
    const reveal = ref(null)
    const currentSlideIndex = ref(0)
    const prepOverview = ref(props.prep_overview)
    const { getConfig, addSlide, removeSlide, updateSlides } = useSlideHelpers(parsedSlides, reveal)
    const {
      prependToStack,
      pushToStack,
      removeNonSharedFromStack,
      replaceInStack,
      activePanel,
      closeSidePanel,
    } = useSidePanelHelpers()

    const currentSlide = computed(() => parsedSlides.value[currentSlideIndex.value])

    provide('reveal', reveal)
    provide('updateSlides', updateSlides)
    provide('parsedSlides', parsedSlides)
    provide('currentEditor', currentEditor)
    provide('saveStatusMessage', saveStatusMessage)
    provide('isSaving', isSaving)
    provide('addSlide', addSlide)
    provide('createPath', props.create_path)
    provide('removeSlide', removeSlide)
    provide('parsedLayouts', parsedLayouts)
    provide('currentSlide', currentSlide)
    provide('prepOverview', prepOverview)

    const parseSlides = () => {
      try {
        parsedSlides.value = JSON.parse(props.slides)
      } catch (e) {
        console.error('Error parsing slides:', e)
      }
    }

    const parseLayouts = () => {
      try {
        parsedLayouts.value = JSON.parse(props.layouts)
      } catch (e) {
        console.error('Error parsing layouts:', e)
      }
    }

    const selectSlide = (index) => {
      currentSlideIndex.value = index
      reveal.value?.slide(index)
    }

    const connectEditPrepButton = () => {
      const editPrepButton = document.querySelector('#editor-prep-button')
      if (editPrepButton) {
        editPrepButton.addEventListener('click', () => {
          pushToStack({
            content: {
              component: 'PrepForm',
              title: 'Prep Overview',
              width: '540px',
              props: {
                updateUrl: props.prep_path
              }
            },
            shared: true
          })
        })
      }
    }

    onMounted(() => {
      parseSlides()
      parseLayouts()
      connectEditPrepButton()

      const onSlideChanged = (event) => {
        const slide = parsedSlides.value[reveal.value.getIndices().h]
        const config = getConfig(slide)

        if (config.side_panel.fixed && activePanel.value?.shared) {
          removeNonSharedFromStack()
          prependToStack({
            content: config.side_panel.content({ slide }),
            fixed: true
          })
        } else if (config.side_panel.fixed) {
          replaceInStack({
            content: config.side_panel.content({ slide }),
            fixed: true
          })
        } else {
          removeNonSharedFromStack()
        }

        currentSlideIndex.value = event.indexh
      }

      reveal.value = new Reveal({
        hash: true,
        width: 1280,
        height: 720,
        controls: false
      })

      reveal.value.initialize().then(() => {
        reveal.value.on('slidechanged', onSlideChanged)

        currentSlideIndex.value = reveal.value.getIndices().h
      })
    })

    watch(parsedSlides, () => {
      nextTick(() => {
        if (reveal.value) {
          reveal.value?.sync()
        }
      })
    })

    return {
      parsedSlides,
      currentEditor,
      editorContainer,
      closeSidePanel,
      currentSlideIndex,
      selectSlide,
    }
  }
}
</script>
