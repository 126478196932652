export const CONFIG = {
  blank: {
    side_panel: {
      fixed: true,
      content: (props = {}) => {
        return {
          title: 'Choose a layout',
          component: 'LayoutForm',
          props: { ...props }
        }
      }
    }
  },
  default: {
    side_panel: {
      fixed: false
    }
  },
  section: {
    side_panel: {
      fixed: true,
      content: (props = {}) => {
        return {
          title: 'Add Question',
          component: 'QuestionForm',
          props: { ...props }
        }
      }
    }
  }
}
