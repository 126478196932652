import VideoForm from './components/embeds/VideoForm.vue'
import QuestionForm from './components/slides/questions/QuestionForm.vue'
import LayoutForm from './components/slides/layouts/LayoutForm.vue'
import PrepForm from './components/prep/PrepForm.vue'

export const SIDE_PANEL_COMPONENTS = {
  VideoForm,
  QuestionForm,
  LayoutForm,
  PrepForm
}
