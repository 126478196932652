<template>
  <div :class="containerClass" @click="onQuestionClick">
    <div v-if="question.question_type === 'open'" class="bg-gray-50 overflow-hidden shadow w-full rounded-lg py-6">
      <div class="flex mb-6 justify-between px-6">
        <!-- Pill header -->
        <div class="flex flex-col items-start">
          <div class="flex w-full">
            <span :class="getHeaderClass('indigo')">
              {{ `Q${question.position_within_lesson} · ${capitalize(question.variant_name)}` }}
            </span>
            <span v-if="question.optional" class="ml-4 inline-flex justify-center items-center px-4 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
              <span class="h-1.5 w-1.5 bg-gray-400 rounded-full mr-2"></span>
              Extension
            </span>
          </div>
          <!-- Question text -->
          <div class="tag text-base text-left mt-6 ml-1">
            <div class="trix-content" v-html="question.text"></div>
          </div>
          <!-- Example answer -->
          <div class="tag text-base text-left mt-6 ml-1 trix-content">
            <span> Example answer: {{ question.example_answer || 'N/A' }} </span>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="question.question_type === 'quiz'" class="bg-gray-50 overflow-hidden shadow w-full rounded-lg py-6" :data-id="question.id" data-test-quiz-result>
      <div class="flex justify-between px-6">
        <!-- Pill header -->
        <div class="flex flex-col items-start">
          <div class="flex w-full">
            <span :class="getHeaderClass('green')">
              {{ `Q${question.position_within_lesson} · ${capitalize(question.variant_name)}` }}
            </span>
            <span v-if="question.optional" class="ml-4 inline-flex justify-center items-center px-4 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
              <span class="h-1.5 w-1.5 bg-gray-400 rounded-full mr-2"></span>
              Extension
            </span>
          </div>
          <!-- Question text -->
          <div class="tag text-base text-left mt-6 ml-1">
            <div class="trix-content" v-html="question.text"></div>
          </div>
        </div>
      </div>

      <!-- Answers -->
      <div class="px-6 pt-6">
        <div
          v-for="(answer, index) in question.answers"
          :key="index"
          :class="['bg-white border-gray-200 relative border-b border-l border-r p-4 grid grid-cols-10 cursor-pointer', getAnswerClasses(index)]"
        >
          <div class="col-span-10 flex justify-start items-center text-left">
            <span class="text-gray-900 block text-sm font-normal mr-3 break-word">
              {{ answer.text }}
            </span>
            <div class="ml-10" v-if="answer.correct">
              <div class="text-green-800 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                </svg>
                <span class="ml-3 text-base">{{ answer.explanation }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="question.question_type === 'poll'" class="bg-gray-50 overflow-hidden shadow w-full rounded-lg py-6" data-test-poll-result>
      <div class="flex justify-between px-6">
        <!-- Pill header -->
        <div class="flex flex-col items-start">
          <div class="flex w-full">
            <span :class="getHeaderClass('yellow')">
              {{ `Q${question.position_within_lesson} · ${capitalize(question.variant_name)}` }}
            </span>
            <span v-if="question.optional" class="ml-4 inline-flex justify-center items-center px-4 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
              <span class="h-1.5 w-1.5 bg-gray-400 rounded-full mr-2"></span>
              Extension
            </span>
          </div>
          <!-- Question text -->
          <div class="tag text-base text-left mt-6 ml-1">
            <div class="trix-content" v-html="question.text"></div>
          </div>
        </div>
      </div>

      <!-- Poll Answers -->
      <div class="px-6 pt-6">
        <div
          v-for="(answer, index) in question.answers"
          :key="index"
          :class="['border-gray-200 bg-white relative border-b border-l border-r p-4 grid grid-cols-10 cursor-pointer', getAnswerClasses(index)]"
        >
          <div class="col-span-9 flex justify-start items-center text-left">
            <span class="text-gray-900 block text-sm font-normal break-word">
              {{ answer.text }}
            </span>
          </div>
          <div
            class="absolute h-full w-0 bg-gradient-to-r from-yellow-400 to-orange-500 opacity-20 z-1 inset-0 transition-width duration-300 ease-in-out"
            :style="{ width: (answer.static_percentage || 0) + '%' }"
          ></div>
        </div>
      </div>
    </div>

    <div v-else-if="question.question_type === 'sortable'" class="bg-gray-50 overflow-hidden shadow w-full rounded-lg py-6 mb-6" data-test-table-result>
      <div class="flex mb-6 justify-between px-6">
        <!-- Pill header -->
        <div class="flex flex-col items-start">
          <div class="flex w-full">
            <span :class="getHeaderClass('indigo')">
              {{ `Q${question.position_within_lesson} · Table` }}
            </span>
            <span v-if="question.optional" class="ml-4 inline-flex justify-center items-center px-4 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
              <span class="h-1.5 w-1.5 bg-gray-400 rounded-full mr-2"></span>
              Extension
            </span>
          </div>
          <!-- Question text -->
          <div class="tag text-base text-left mt-6 ml-1">
            <div class="trix-content" v-html="question.text"></div>
          </div>
        </div>
      </div>

      <div class="flex flex-col mt-6 px-6">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden flex sm:rounded-lg sortable-question">
              <table class="w-full divide-y divide-gray-200 table-fixed" style="flex: 6">
                <thead class="bg-gray-50">
                  <tr>
                    <th v-if="question.labels_have_values" scope="col" data-label-header class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-20">
                    </th>
                    <th
                      v-for="(header, index) in question.headers_values"
                      :key="index"
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ header }}
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <!-- Sortable question content will go here -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { capitalize } from '@/helpers'
import { useSidePanelHelpers } from '../../../composables/useSidePanelHelpers'
export default {
  name: 'Question',
  props: {
    question: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { replaceInStack } = useSidePanelHelpers()

    const getHeaderClass = (variant) => {
      return `inline-flex justify-center items-center px-4 py-0.5 rounded-full text-xs font-medium bg-${variant}-100 text-${variant}-800`
    }

    const getAnswerClasses = (index) => {
      const classes = []
      if (index === 0) {
        classes.push('rounded-tl-md', 'rounded-tr-md', 'border-t')
      }
      if (index === props.question.answers.length - 1) {
        classes.push('rounded-bl-md', 'rounded-br-md')
      }
      return classes.join(' ')
    }

    const containerClass = computed(() => {
      return `${props.question.question_type === 'sortable' ? 'w-full' : 'w-1/3'} p-3 cursor-pointer`
    })

    const onQuestionClick = () => {
      replaceInStack({
        content: {
          title: 'Question Settings',
          component: 'QuestionForm',
          props: { question: props.question }
        }
      })
    }

    return {
      capitalize,
      getHeaderClass,
      getAnswerClasses,
      containerClass,
      onQuestionClick
    }
  }
}
</script>
