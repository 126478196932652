<template>
  <div class="grid grid-cols-2 gap-1 h-full overflow-scroll pb-8">
    <div v-for="layout in parsedLayouts" :key="layout.id">
      <SlideThumbnail :slide="layout" @click="selectLayout(layout)" />
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'
import SlideThumbnail from '../SlideThumbnail.vue'
import { useSlideUpdate } from '../../../composables/useSlideUpdate'
import { useSidePanelHelpers } from '../../../composables/useSidePanelHelpers'
import { useConfirmationDialog } from '../../../composables/useConfirmationDialog'

export default {
  name: 'LayoutForm',
  components: {
    SlideThumbnail
  },
  setup() {
    const currentSlide = inject('currentSlide')
    const parsedLayouts = inject('parsedLayouts')
    const { updateSlide } = useSlideUpdate()
    const { closeSidePanel } = useSidePanelHelpers()

    const selectLayout = async (layout) => {
      const confirmation = await useConfirmationDialog({
        title: 'Switch layout',
        body: "Are you sure you want to switch to this layout? Some content may be lost if it doesn't fit the new layout.",
        confirmBtnText: 'Switch',
        cancelBtnText: 'Cancel'
      })

      if (confirmation) {
        updateSlide(currentSlide.value.layout_path, { slide: { slide_layout_id: layout.id } })
        closeSidePanel()
      }
    }

    return {
      selectLayout,
      parsedLayouts
    }
  }
}
</script>

<style scoped>
  .thumbnail-item {
    transition: border 0.3s ease-in-out;

    @apply cursor-pointer border-2 border-transparent;
  }

  .thumbnail-item:hover {
    border: 2px solid #8ee5fb;
  }

</style>
